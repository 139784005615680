import React, { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Notification from './notification'

export default function ContactForm({ id }) {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [submitting, setSubmitting] = useState(false)
  const [show, setShow] = useState(false)
  const [success, setSuccess] = useState(true)

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!executeRecaptcha) return

    setSubmitting(true)

    try {
      gtag("event", "contact_submitted", {
        method: "ContactForm"
      });
    } catch (e) {
    }
    try {
      const token = await executeRecaptcha('contact_us')

      const elements = e.target.elements
      const data = {
        forename: elements['first-name'].value,
        surname: elements['last-name'].value,
        email: elements.email.value,
        company: elements.company.value,
        phone: elements.phone.value,
        message: elements['how-can-we-help'].value,
        // budget: elements.budget.value,
        lead: elements['how-did-you-hear-about-us'].value,
        recaptcha: token,
      }

      const response = await fetch(process.env.GATSBY_CONTACT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(data)
      })

      console.log('response', response)

      setSuccess(response.ok)
      setShow(true)
      setInterval(() => setShow(false), 5000)
      try {
        gtag("event", "contact_success", {
          method: "ContactForm"
        });
      } catch (e) {
      }
    } catch (error) {
      console.log('response error', error)
      try {
        gtag("event", "contact_error", {
          method: "ContactForm"
        });
      } catch (e) {
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div id={id || 'contact-us'} className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover lg:absolute lg:h-full"
            src="https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80"
            alt=""
          />
        </div>
      </div>
      <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="hidden lg:block absolute top-0 left-0 bg-teal-100 mt-32 -ml-16" style={{ width: 404, height: 384 }}>
          <svg
            className="hidden lg:block absolute top-0 left-0 -mt-32 -ml-48"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="3bac36b2-d72e-4b53-b99b-6925755fa88a"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-teal-400" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#3bac36b2-d72e-4b53-b99b-6925755fa88a)" />
          </svg>
        </div>

        <div className="-mt-16 pt-6 lg:pl-6 lg:pr-8 bg-white bg-opacity-50 z-10">
          <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Let's work together</h2>
            <p className="mt-4 text-lg text-gray-600 sm:mt-3">
              We’d love to hear from you! Send us a message using the form below giving us a short explaination of your idea or the problem you are looking to solve.
            </p>
            <form method="POST" onSubmit={onSubmit} className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
              <div>
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  First name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full shadow-sm sm:text-sm focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  Last name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full shadow-sm sm:text-sm focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                    required
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full shadow-sm sm:text-sm focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                    required
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                  Company
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    autoComplete="organization"
                    className="block w-full shadow-sm sm:text-sm focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                    Phone
                  </label>
                  <span id="phone-description" className="text-sm text-gray-500">
                    Optional
                  </span>
                </div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    aria-describedby="phone-description"
                    className="block w-full shadow-sm sm:text-sm focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label htmlFor="how-can-we-help" className="block text-sm font-medium text-gray-700">
                    How can we help you?
                  </label>
                  <span id="how-can-we-help-description" className="text-sm text-gray-500">
                    Max. 500 characters
                  </span>
                </div>
                <div className="mt-1">
                  <textarea
                    id="how-can-we-help"
                    name="how-can-we-help"
                    aria-describedby="how-can-we-help-description"
                    rows={4}
                    maxLength={500}
                    className="block w-full shadow-sm sm:text-sm focus:ring-teal-500 focus:border-teal-500 border border-gray-300 rounded-md"
                    defaultValue={''}
                    required
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label htmlFor="how-did-you-hear-about-us" className="block text-sm font-medium text-gray-700">
                    How did you hear about us?
                  </label>
                  <span id="phone-description" className="text-sm text-gray-500">
                    Optional
                  </span>
                </div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="how-did-you-hear-about-us"
                    id="how-did-you-hear-about-us"
                    className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="text-right sm:col-span-2">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-300 hover:bg-teal-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                >
                  {
                    submitting ? (
                      <>
                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Submitting
                      </>
                    ) : 'Submit'
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {
        success ? (
          <Notification
            title="Message successfully sent"
            description="Thanks for getting in touch, we'll get back to you as soon as possible."
            show={show}
            setShow={setShow}
          />
        ) : (
          <Notification
            title="Message failed to send"
            description={`Thanks for trying to get in touch it's been unsuccessful this time, please try again later.`}
            success={false}
            show={show}
            setShow={setShow}
          />
        )
      }
    </div>
  )
}
